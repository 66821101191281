<template>
  <resource-search @delete="() => this.$emit('delete')" :disabled="disabled" @input="id => this.$emit('input', id)" @selected="selected => this.$emit('selected', selected)" module="properties" :show-delete="showDelete" :value="value">
    <template #popover-list="{ result }">
      <slot name="popover-list" :result="result">
        <p class="font-weight-bold mb-0">{{ result.name }}</p>
        <p class="text-muted mb-0" v-if="showMaxGuests"><small>Max Guests: {{ result.max_guests }}</small></p>
      </slot>
    </template>

    <template #selected="{ selected }">
      <slot name="selected" :selected="selected">
        <p class="font-weight-bold mb-0">{{ selected.name }}</p>
        <p class="text-muted mb-0" v-if="showMaxGuests"><small>Max Guests: {{ selected.max_guests }}</small></p>
      </slot>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    showMaxGuests: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    value: Number,
  }
}
</script>

<style>

</style>
