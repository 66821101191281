<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.maintenanceId ? 'View/Edit' : 'Create'} Maintenance`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Property" :disabled="editedMaintenance.property_id" :invalid-feedback="getValidationMessage('property_id')" :state="!validationErrors.property_id">
                <property-search @delete="editedMaintenance.property_id = null" show-delete v-model="editedMaintenance.property_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="User">
                <user-search type="maintenance" v-model="editedMaintenance.user_id" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Maintenance Contractor">
                <maintenance-contractor-search v-model="editedMaintenance.maintenance_contractor_id" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p class="text-muted"><small>Please specify only one: a user, or a contractor; to take on this job.</small></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Priority">
                <b-form-select v-model="editedMaintenance.status" :options="['Low', 'Medium', 'High']" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Quick Summary">
                <b-form-textarea rows="2" v-model="editedMaintenance.summary" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-textarea rows="2" v-model="editedMaintenance.description" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col cols="12" v-if="editedMaintenance.id">
            <b-card class="mb-2" v-for="visit in editedMaintenance.visits" :key="visit.id">
              <b-row>
                <b-col cols="10">
                  <b-form-group label="Summary">
                    <b-form-input type="text" v-model="visit.summary" />
                  </b-form-group>
                </b-col>
                <b-col class="d-flex flex-column justify-content-end" cols="2">
                  <b-button block class="mb-3" @click.prevent="removeVisit(visit)" variant="danger"><fa-icon icon="trash" /></b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <template #label>
                      <label class="mb-0">Date</label>
                    </template>
                    <b-form-datepicker v-model="visit.visit_at" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <template #label>
                      <label class="mb-0">Start Time</label>
                    </template>
                    <b-form-timepicker v-model="visit.start_time" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <template #label>
                      <label class="mb-0">End Time</label>
                    </template>
                    <b-form-timepicker v-model="visit.end_time" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
            <b-button block @click.prevent="addVisit" variant="primary">Add Visit...</b-button>
          </b-col>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveMaintenance" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.maintenanceId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import PropertySearch from '../../components/PropertySearch.vue'
import handleErrors from '../../mixins/handleErrors'
import UserSearch from "@/components/UserSearch.vue";
import MaintenanceContractorSearch from "@/components/MaintenanceContractorSearch.vue";

export default {
  components: {UserSearch, PropertySearch, MaintenanceContractorSearch },
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedMaintenance: {
        id: null,
        property_id: null,
        team_id: null,
        maintenance_contractor_id: null,
        summary: '',
        description: '',
        status: null,
        visits: [{
          id: null,
          visit_at: null,
          start_time: null,
          end_time: null,
          summary: ''
        }]
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    maintenance: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.maintenanceId
      && this.maintenance
    ) {
      this.loading = true;

      try {
        const maintenance = await this.fetchSingle(this.$route.params.maintenanceId);
        const duration = moment.duration(maintenance?.duration_min ?? 0, 'minutes')
        this.editedMaintenance = {
          ...maintenance,
          duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
        };

        this.loading = false;
      } catch (err) {
        this.error = "Can not load maintenance. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('maintenance', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.editedMaintenance = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveMaintenance() {
      this.saving = true;
      this.updateOrCreate(this.editedMaintenance).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    addVisit() {
      this.editedMaintenance.visits.push({
        id: null,
        visit_at: null,
        leave_at: null,
        summary: ''
      });
    },
    removeVisit(visit)
    {
      this.editedMaintenance.visits.splice(this.editedMaintenance.visits.indexOf(visit), 1);
    }
  },
  watch: {
    maintenance(value) {
      if (value === undefined) {
        return
      }

      const duration = moment.duration(value?.duration_min ?? 0, 'minutes')
      this.editedMaintenance = {
        ...value,
        duration_min: `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
      };
    }
  }
}
</script>

<style>

</style>
