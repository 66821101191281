<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="maintenanceContractors" display-name="Maintenance Contractors" :show-delete="showDelete" :fetch-params="extraFetchParams" :value="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ result.company_name }}</p>
      <p class="text-muted mb-0"><small>{{ result.trade.name }}</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ selected.company_name }}</p>
      <p class="text-muted mb-0"><small>{{ selected.trade.name }}</small></p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    value: Number,
    type: String,
  },
  computed: {
    extraFetchParams() {
      return {};
    }
  }
}
</script>

<style>

</style>
